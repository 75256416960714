import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Label from "~/models/label";
import Tag from "~/views/elements/generic/tag";
import Forward from "~/views/identity/forward";
import Logo from "~/views/identity/logo";
import Collection from "~/models/collection";
import X from "~/views/identity/social/x";
import Instagram from "~/views/identity/social/instagram";
import Figma from "~/views/identity/social/figma";
import RSS from "~/views/identity/social/rss";

const contents: any = [
  {
    name: "最新記事",
    url: "/articles/",
  },
  {
    name: "最新ニュース",
    url: "/news/",
  },
  {
    name: "連載記事",
    url: "/series/",
  },
  {
    name: "デザインイベント",
    url: "/events/",
  },
  {
    name: "アプリデザインギャラリー",
    url: "/explore/",
  },
  {
    name: "UXデザインテンプレート",
    url: "/resources/",
  },
];

const categories: any = [
  {
    name: "UXデザイン",
    url: "/ux-design/",
  },
  {
    name: "UIデザイン",
    url: "/ui-design/",
  },
  {
    name: "Webデザイン",
    url: "/web-design/",
  },
  {
    name: "ブランディング",
    url: "/branding/",
  },
  {
    name: "グラフィックデザイン",
    url: "/graphic-design/",
  },
  {
    name: "ビジネス",
    url: "/business/",
  },
];

const topics: any = [
  {
    name: "より良い未来の探求",
    url: "/tags/exploring-a-better-future/",
  },
  {
    name: "文化と生活",
    url: "/tags/culture-and-lifestyle/",
  },
  {
    name: "働くを楽しむ",
    url: "/tags/workstyle/",
  },
  {
    name: "未来をつくる",
    url: "/tags/shaping-a-better-future/",
  },
];

type Props = {
  className?: string;
};

function Footer({ className }: Props) {
  const { allContentfulLabel, contentfulCollectionPage }: Queries.FooterQuery =
    useStaticQuery(
      graphql`
        query Footer {
          allContentfulLabel(limit: 20) {
            nodes {
              ...LabelFields
            }
          }
          contentfulCollectionPage(slug: { eq: "footer" }) {
            id
            title
            items {
              id
              internalTitle
              title
              description {
                description
              }
              image {
                thumbnail {
                  childImageSharp {
                    gatsbyImageData(
                      width: 700
                      placeholder: NONE
                      formats: [WEBP]
                    )
                  }
                  publicURL
                }
              }
              url {
                ...UrlFields
              }
              releasedAt
              updatedAt
              labels {
                ...LabelFields
              }
            }
          }
        }
      `
    );

  const labels = allContentfulLabel.nodes.map(
    (label) => new Label(label as Queries.ContentfulLabel)
  );

  const collection = new Collection(
    contentfulCollectionPage as Queries.ContentfulCollectionPage
  );

  return (
    <div className="max-w-[1404px] mx-auto px-5 sm:px-[38px]">
      <div className="flex flex-col lg:flex-row gap-10 py-10">
        <div className="flex flex-col sm:flex-row flex-1 gap-10 justify-between">
          <div className="flex-1 sm:max-w-[200px] lg:max-w-[200px]">
            <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
              Contents
            </h4>
            <ul className="marker:text-neutral-90 list-outside list-disc pl-4">
              {contents.map((item: any) => (
                <li
                  key={item.name}
                  className="mb-4 last:mb-0 text-sm font-semibold hover:highlighted-link">
                  <Link
                    className="break-keep"
                    aria-label={item.name}
                    to={item.url}>
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex-1 sm:max-w-[200px] lg:max-w-[200px]">
            <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
              Topics
            </h4>
            <ul>
              {topics.map((item: any) => (
                <li
                  key={item.name}
                  className="mb-4 last:mb-0 text-sm font-semibold hover:highlighted-link">
                  <Link
                    className="break-keep flex gap-1 items-center"
                    aria-label={item.name}
                    to={item.url}>
                    <span className="text-neutral-90 font-extrabold">#</span>
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          <div className="flex-1 sm:max-w-[200px] lg:max-w-[200px]">
            <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
              Categories
            </h4>
            <ul>
              {categories.map((item: any) => (
                <li
                  key={item.name}
                  className="mb-4 last:mb-0 text-sm font-semibold hover:highlighted-link">
                  <Link
                    className="break-keep flex gap-1 items-center"
                    aria-label={item.name}
                    to={item.url}>
                    <span className="text-neutral-90 font-extrabold">#</span>
                    <span>{item.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="flex-1">
          <h4 className="mb-4 text-2xl font-extrabold text-neutral-90">
            Pickups
          </h4>
          <ul className="marker:text-neutral-90 list-outside list-disc pl-4">
            {collection.items.map((item: any) => (
              <li
                key={item.id}
                className="mb-4 last:mb-0 sm:text-xs leading-[20px] text-xs hover:underlined-link">
                <Link aria-label={item.title} to={item.url.pathname}>
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-12 py-10 border-t border-neutral-90">
        <div>
          <Link
            aria-label="unprinted"
            to="/"
            className="block mb-5 w-[148px] md:w-[185px]">
            <Logo className="w-[160px] md:w-[185px]" />
          </Link>

          <p className="text-xs leading-[1.8]">
            unprinted（アンプリンテッド）は、「より良い未来」をつくるデザイナーのためのWork
            & Lifeマガジンです。
            unprintedへのお問い合わせ、コンテンツ制作に関わってみたいという方は、
            <Link
              aria-label="コンタクトフォーム"
              to="/contact/"
              className="underline underline-offset-2 hover:inline-link">
              コンタクトフォーム
            </Link>
            よりご連絡ください。
          </p>
        </div>

        <div>
          <div className="flex gap-12 justify-center md:justify-end mb-8">
            <Link
              aria-label="unprintedについて"
              to="/about-us"
              className="font-semibold text-sm hover:highlighted-link">
              unprintedについて
            </Link>
            <Link
              aria-label="お問い合わせ"
              to="/contact"
              className="font-semibold text-sm hover:highlighted-link">
              お問い合わせ
            </Link>
          </div>

          <div className="flex gap-8 justify-center md:justify-end mb-8">
            <a
              href="https://twitter.com/unprinteddesign"
              rel="noopener noreferrer"
              target="_blank"
              className="shrink-0">
              <X width={24} height={24} />
            </a>

            <a
              href="https://www.instagram.com/unprinted.design/"
              rel="noopener noreferrer"
              target="_blank"
              className="shrink-0">
              <Instagram width={24} height={24} />
            </a>

            <a
              href="https://www.figma.com/@unprinted"
              rel="noopener"
              target="_blank"
              className="shrink-0">
              <Figma width={24} height={24} />
            </a>

            <a href="/rss.xml" target="_blank" className="shrink-0">
              <RSS width={24} height={24} />
            </a>
          </div>

          <div className="flex-col sm:flex-row flex gap-5 sm:gap-7 justify-center md:justify-end">
            <Link
              aria-label="利用規約"
              className="text-3xs tracking-[0.02] hover:underline underline-offset-2"
              to="/terms-of-use/">
              利用規約
            </Link>
            <Link
              aria-label="プライバシーポリシー"
              className="text-3xs tracking-[0.02] hover:underline underline-offset-2"
              to="/privacy-policy/">
              プライバシーポリシー
            </Link>
            <Link
              aria-label="利用者情報の外部送信"
              className="text-3xs tracking-[0.02] hover:underline underline-offset-2"
              to="/external-services/">
              利用者情報の外部送信
            </Link>
            <p className="text-4xs tracking-[0.02]">
              &copy;{new Date().getFullYear()} unprinted
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
