export default class Contributor {
  id: string;
  name: string;
  biography: string;
  website?: string;
  twitter?: string;
  instagram?: string;
  behance?: string;
  dribbble?: string;
  linkedin?: string;
  role?: string;

  constructor(contributor: Queries.ContentfulContributor) {
    this.id = contributor.id;
    this.name = contributor.name!;
    this.biography = contributor.biography?.biography || "";

    if (contributor.website) {
      this.website = contributor.website;
    }

    if (contributor.twitter) {
      this.twitter = contributor.twitter;
    }

    if (contributor.instagram) {
      this.instagram = contributor.instagram;
    }

    if (contributor.behance) {
      this.behance = contributor.behance;
    }

    if (contributor.dribbble) {
      this.dribbble = contributor.dribbble;
    }

    if (contributor.linkedin) {
      this.linkedin = contributor.linkedin;
    }

    if (contributor.role) {
      this.role = contributor.role;
    }
  }
}
