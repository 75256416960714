const crypto = require("crypto");
import React from "react";
import cx from "classnames";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { INLINES, BLOCKS, MARKS } from "@contentful/rich-text-types";
import {
  renderRichText,
  RenderRichTextData,
  ContentfulRichTextGatsbyReference,
} from "gatsby-source-contentful/rich-text";
import Blcokquote from "~/views/components/content/blockquote";
import ExternalLink from "~/views/components/content/external-link";
import InternalLink from "~/views/components/content/internal-link";
import AppLink from "~/views/components/content/app-link";
import Media from "~/views/components/content/media";
import Callout from "~/views/components/content/callout";
import Code from "~/views/components/content/code";
import Tweet from "~/views/components/content/tweet";
import InlineLink from "~/views/components/content/inline-link";
import ContributorCard from "~/views/components/content/contributor";
import TwitterCTA from "~/views/components/content/twitter-cta";
import NewsletterCTA from "~/views/components/content/newsletter-cta";
import Podcast from "~/views/components/content/podcast";
import Spotify from "~/views/components/content/spotify";
import Figma from "~/views/components/content/figma";
import FAQ from "~/views/components/content/faq";
import Resource from "./resource";
import useImageModal from "~/helpers/use-image-modal";
import Quiz from "~/views/components/content/quiz";
import Note from "~/views/components/content/note";
import Book from "~/views/components/content/book";
import InlineTag from "~/views/components/content/tag";
import InlineAffiliateLink from "~/views/components/content/affiliate-link";
import Summary from "~/views/components/content/summary";
import Flow from "~/views/components/content/flow";
import AffiliateBanner from "~/views/components/content/affiliate-banner";

const roles: any = {
  Writer: "執筆",
  Editor: "編集",
  Photographer: "撮影",
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text: string) => <b className="font-bold">{text}</b>,
    [MARKS.UNDERLINE]: (text: string) => (
      <u className="text-underline font-bold">{text}</u>
    ),
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node: any, children: string) => {
      const value = node.content.reduce((v: string, c: any) => {
        return (v += c.value);
      }, "");

      return (
        <div
          id={crypto.createHash("md5").update(value).digest("hex")}
          className="heading mt-16 sm:mt-[128px] mb-6 sm:mb-8">
          <h2 className="font-bold text-xl sm:text-5xl mb-2">{children}</h2>
          <div className="h-[2px] bg-neutral-98 relative">
            <div className="absolute left-0 h-[2px] w-[20%] bg-cyan"></div>
          </div>
        </div>
      );
    },
    [BLOCKS.HEADING_3]: (node: any, children: string) => {
      const value = node.content[0].value;

      return (
        <h3
          id={crypto.createHash("md5").update(value).digest("hex")}
          className="heading mt-12 sm:mt-[90px] mb-8 font-bold text-xl sm:text-3xl leading-[1.6] bg-neutral-99 py-1 px-3 border-l-4 border-cyan">
          {children}
        </h3>
      );
    },
    [BLOCKS.HEADING_4]: (node: any, children: string) => (
      <h4 className="border-l-4 border-cyan pl-3 lg:pl-2 mb-8 font-bold leading-[2.0]">
        {children}
      </h4>
    ),
    [BLOCKS.HEADING_5]: (node: any, children: string) => (
      <h5 className="mb-8 font-bold text-4 leading-[2.0]">{children}</h5>
    ),
    [BLOCKS.UL_LIST]: (node: any, children: any) => {
      return (
        <ul className="mb-12 sm:mb-16 px-5 lg:px-10 bg-neutral-99 rounded sm:rounded-lg marker:text-cyan list-outside list-disc">
          {children}
        </ul>
      );
    },
    [BLOCKS.OL_LIST]: (node: any, children: any) => (
      <ol className="mb-12 sm:mb-16 px-5 lg:px-10 bg-neutral-99 rounded-lg list-decimal list-outside marker:text-cyan">
        {children}
      </ol>
    ),
    [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
      return (
        <li className="richtext-list-item ml-4 py-3 sm:py-4 font-medium text-xs sm:text-sm leading-[1.6] text-neutral-30">
          {children}
        </li>
      );
    },
    [BLOCKS.TABLE]: (node: any, children: any) => {
      return (
        <div className="pb-1 [overflow:overlay] mb-12 sm:mb-16">
          <table className="richtext-table border-2 border-neutral-90 shadow-neutral rounded-lg border-spacing-0 border-separate overflow-hidden w-full">
            <tbody>{children}</tbody>
          </table>
        </div>
      );
    },
    [BLOCKS.TABLE_ROW]: (node: any, children: any) => (
      <tr className="border-neutral-90 dashed-b last:bg-none">{children}</tr>
    ),
    [BLOCKS.TABLE_HEADER_CELL]: (node: any, children: any) => (
      <th className="break-keep bg-neutral-99 py-5 px-4 border-r-2 border-r-neutral-90 last:border-r-0 dashed-b text-xs sm:text-sm">
        {children}
      </th>
    ),
    [BLOCKS.TABLE_CELL]: (node: any, children: any) => (
      <td className="break-keep py-5 px-4 border-r-2 border-neutral-90 last:border-r-0 text-xs sm:text-sm">
        {children}
      </td>
    ),
    [INLINES.HYPERLINK]: (node: any, children: string) => (
      <InlineLink to={node.data.uri}>{children}</InlineLink>
    ),
    [BLOCKS.PARAGRAPH]: (node: any, children: string) => (
      <p className="mb-8 text-neutral-30 leading-8 font-regular">{children}</p>
    ),
    [BLOCKS.QUOTE]: (node: any, children: any) => {
      return (
        <div className="richtext-quote flex gap-2 mb-8 sm:gap-6 items-start p-4 sm:p-8 bg-neutral-99 rounded-lg text-neutral-30 font-regular">
          <img
            src="/images/quote.svg"
            width="24"
            height="24"
            alt="Quote"
            loading="lazy"
          />
          <div>
            <div className="font-medium leading-8 break-all">{children}</div>
          </div>
        </div>
      );
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: any) => {
      const { handleActivation } = useImageModal();
      const { localFile, title, description, mimeType } = node.data.target;
      const { tags, content } = extractTags(description);

      if (mimeType.indexOf("image") != -1) {
        return (
          <div
            className={cx("my-8 sm:my-16 cursor-zoom-in", {
              "mb-6 sm:mb-8": tags.indexOf("max-height=356") != -1,
            })}
            onClick={() => handleActivation(localFile, tags)}>
            <div
              className={cx({
                "bg-neutral-99 pt-4 sm:pt-6 pb-4 px-4 sm:px-6 rounded sm:rounded-lg":
                  tags.indexOf("background-gray") != -1,
              })}>
              <GatsbyImage
                className={cx(`block rounded-lg isolate bg-neutral-99`, {
                  "border-2 border-cyan shadow-cyan":
                    tags.indexOf("border") != -1,
                  "w-[260px] mx-auto border border-neutral-90-0.6":
                    tags.indexOf("screenshot") != -1 &&
                    tags.indexOf("mobile") != -1,
                  "border border-neutral-90-0.6":
                    tags.indexOf("border-gray") != -1,
                  "!max-h-[356px] !mx-auto":
                    tags.indexOf("max-height=356") != -1,
                  "!max-w-[480px] !mx-auto":
                    tags.indexOf("max-width=480") != -1,
                })}
                image={getImage(localFile)!}
                alt={title}
                style={{
                  aspectRatio: `${localFile.childImageSharp.gatsbyImageData.width}/${localFile.childImageSharp.gatsbyImageData.height}`,
                }}
              />
            </div>

            {content && (
              <p
                className="mx-auto mt-2 text-xs text-neutral-60 break-all"
                style={{
                  maxWidth:
                    tags.indexOf("max-height=356") != -1 &&
                    tags.indexOf("background-gray") == -1
                      ? `${
                          356 *
                          (localFile.childImageSharp.gatsbyImageData.width /
                            localFile.childImageSharp.gatsbyImageData.height)
                        }px`
                      : tags.indexOf("max-width=480") != -1
                      ? "480px"
                      : "auto",
                }}>
                {content}
              </p>
            )}
          </div>
        );
      }

      if (mimeType.indexOf("video") != -1) {
        return (
          <div
            className={cx("my-8 sm:my-16", {
              "mb-6 sm:mb-8": tags.indexOf("max-height=356") != -1,
            })}>
            <video
              autoPlay
              muted
              loop
              playsInline
              className={cx("rounded-lg isolate", {
                "border-2 border-cyan shadow-cyan":
                  tags.indexOf("border") != -1,
                "border border-neutral-90-0.6":
                  tags.indexOf("border-gray") != -1,
                "w-[260px] mx-auto border border-neutral-90-0.6":
                  tags.indexOf("screenshot") != -1 &&
                  tags.indexOf("mobile") != -1,
                "!max-h-[356px] !mx-auto": tags.indexOf("max-height=356") != -1,
              })}>
              <source src={localFile.publicURL} type={mimeType} />
            </video>
            {content && (
              <p className="mt-2 text-xs text-neutral-60 break-all">
                {content}
              </p>
            )}
          </div>
        );
      }
    },
    [INLINES.EMBEDDED_ENTRY]: (node: any) => {
      const { type } = node.data.target.internal;

      if (type == "ContentfulInlineAffiliateLink") {
        return <InlineAffiliateLink node={node} />;
      }

      if (type == "ContentfulInlineTag") {
        return <InlineTag node={node} />;
      }

      // if (type == "ContentfulContributor") {
      //   return (
      //     <div className="inline-block mb-1 mr-5">
      //       <div className="flex gap-[6px]">
      //         <span className="text-3xs font-semibold text-neutral-60 leading-[32px]">
      //           {node.data.target.role ? roles[node.data.target.role] : "by"}
      //         </span>
      //         <span className="text-neutral-30 leading-[32px] font-semibold">
      //           {node.data.target.name}
      //         </span>
      //       </div>
      //     </div>
      //   );
      // }
    },
    [BLOCKS.EMBEDDED_ENTRY]: (node: any) => {
      const { type } = node.data.target.internal;

      if (type == "ContentfulComponentAffiliateBanner") {
        return <AffiliateBanner node={node} />;
      }

      if (type == "ContentfulComponentFlow") {
        return <Flow node={node} />;
      }

      if (type == "ContentfulComponentBook") {
        return <Book node={node} />;
      }

      if (type == "ContentfulComponentQuiz") {
        return <Quiz node={node} />;
      }

      if (type == "ContentfulComponentBlockquote") {
        return <Blcokquote node={node} />;
      }

      if (type == "ContentfulComponentLink") {
        if (node.data.target.url.indexOf("www.youtube.com") != -1) {
          return <Media node={node} />;
        }

        return <ExternalLink node={node} />;
      }

      if (type == "ContentfulComponentApp") {
        return <AppLink node={node} />;
      }

      if (type == "ContentfulPage") {
        return <InternalLink node={node} />;
      }

      if (type == "ContentfulComponentCallout") {
        return <Callout node={node} />;
      }

      if (type == "ContentfulComponentCode") {
        return <Code node={node} />;
      }

      if (type == "ContentfulComponentNote") {
        return <Note node={node} />;
      }

      if (type == "ContentfulComponentEmbed") {
        if (node.data.target.type == "Tweet") {
          return <Tweet node={node} />;
        }

        if (node.data.target.type == "Podcast") {
          return <Podcast node={node} />;
        }

        if (node.data.target.type == "Spotify") {
          return <Spotify node={node} />;
        }

        if (node.data.target.type == "Figma") {
          return <Figma node={node} />;
        }

        return (
          <div className="my-8 sm:my-16 mx-auto flex flex-row justify-center">
            <div
              className="overflow-hidden isolate w-full"
              dangerouslySetInnerHTML={{ __html: node.data.target.code.code }}
            />
          </div>
        );
      }

      if (type == "ContentfulComponentContributor") {
        return <ContributorCard node={node} />;
      }

      if (type == "ContentfulComponentNewsletterCta") {
        return <NewsletterCTA node={node} />;
      }

      if (type == "ContentfulComponentTwitterCta") {
        return <TwitterCTA node={node} />;
      }

      if (type == "ContentfulComponentFaq") {
        return <FAQ node={node} />;
      }

      if (type == "ContentfulComponentResource") {
        return <Resource node={node} />;
      }

      if (type == "ContentfulComponentSummary") {
        return <Summary node={node} />;
      }
    },
  },
};

const extractTags = (value: string) => {
  if (!value) {
    return {
      tags: [],
      content: null,
    };
  }

  let tags = [] as any;
  let content = value;

  for (const match of value.matchAll(/\[(.*?)\]/g)) {
    tags = match[1].replace(/\s/g, "").split(",");
    content = value.replace(match[0], "").trim();
  }

  return {
    tags,
    content,
  };
};

type Props = {
  id?: string;
  className?: string;
  children?: RenderRichTextData<ContentfulRichTextGatsbyReference>;
};

const RichText = ({ id, className, children }: Props) => {
  if (!children) return <div></div>;

  return (
    <div id={id} className={cx(className)}>
      {renderRichText(children, options as any)}
    </div>
  );
};

export default RichText;
