import navigationReducer from "./navigation";
import imageModalReducer from "./image-modal";
import flashReducer from "./flash";

import { logger } from "./middlewares";

interface State {
  navigation: any;
  imageModal: any;
  flash: any;
}

export const initialState: State = {
  navigation: navigationReducer.initialState,
  imageModal: imageModalReducer.initialState,
  flash: flashReducer.initialState,
};

export default function mainReducer(state: State, action: object) {
  // Receiving previous state here
  const { navigation, imageModal, flash } = state;

  // Receiving current state here
  const currentState = {
    navigation: navigationReducer.reducer(navigation, action),
    imageModal: imageModalReducer.reducer(imageModal, action),
    flash: flashReducer.reducer(flash, action),
  };

  // Middlewares
  // logger(action, state, currentState);

  return currentState;
}
