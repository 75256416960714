import React, { useEffect } from "react";
import { globalHistory } from "@reach/router";
import cx from "classnames";
import useNavigation from "~/helpers/use-navigation";
import Footer from "~/views/compositions/navigation/footer";
import ImageModal from "~/views/compositions/generic/image-modal";
import FlashMessage from "~/views/components/generic/flash";
import useImageModal from "~/helpers/use-image-modal";
import useFlash from "~/helpers/use-flash";

type Props = {
  children: JSX.Element | JSX.Element[];
  fixedHeader?: boolean;
};

Frame.defaultProps = {
  fixedHeader: true,
};

function Frame({ children, fixedHeader }: Props) {
  const { handleDeactivation } = useNavigation();
  const imageModalState = useImageModal();
  const flashState = useFlash();

  useEffect(() => {
    const unlisten = globalHistory.listen(({ action }) => {
      if (["PUSH", "POP"].indexOf(action) != -1) {
        setTimeout(() => {
          handleDeactivation();
        }, 100);
      }
    });

    return function cleanup() {
      unlisten();
    };
  }, []);

  return (
    <div className="min-w-[375px] text-black font-base">
      {flashState.mounted && <FlashMessage />}
      {imageModalState.mounted && <ImageModal />}

      <main
        className={cx({ "pt-[58px] sm:pt-[64px] md:pt-[113px]": fixedHeader })}
      >
        {children}
      </main>
      <Footer />
    </div>
  );
}

export default Frame;
