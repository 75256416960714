import React from "react";
import cx from "classnames";
import Contributor from "~/models/contributor";
import X from "~/views/identity/contributor/x";
import Instagram from "~/views/identity/contributor/instagram";
import Dribbble from "~/views/identity/contributor/dribbble";
import Behance from "~/views/identity/contributor/behance";
import LinkedIn from "~/views/identity/contributor/linkedin";

type Props = {
  contributor: Contributor;
};

const roles: any = {
  Writer: "執筆",
  Editor: "編集",
  Photographer: "撮影",
};

function ContributorCard({ contributor }: Props) {
  return (
    <div className="mt-10">
      <div className="flex gap-[6px] mb-1">
        <span className="text-3xs font-semibold text-neutral-60 leading-[32px]">
          {contributor.role ? roles[contributor.role] : "by"}
        </span>
        <span className="text-neutral-30 leading-[32px] font-semibold">
          {contributor.name}
        </span>
      </div>

      {contributor.biography && (
        <p className="text-xs leading-[2em] mb-1">{contributor.biography}</p>
      )}

      {contributor.website && (
        <a
          href={contributor.website}
          rel="noopener"
          target="_blank"
          className="inline-block mb-4 text-sm text-neutral-60 underline hover:text-cyan-strong"
        >
          {contributor.website}
        </a>
      )}

      <div className="flex gap-6">
        {contributor.twitter && (
          <a rel="noopener" target="_blank" href={contributor.twitter}>
            <X />
          </a>
        )}
        {contributor.instagram && (
          <a rel="noopener" target="_blank" href={contributor.instagram}>
            <Instagram />
          </a>
        )}
        {contributor.behance && (
          <a rel="noopener" target="_blank" href={contributor.behance}>
            <Behance />
          </a>
        )}
        {contributor.dribbble && (
          <a rel="noopener" target="_blank" href={contributor.dribbble}>
            <Dribbble />
          </a>
        )}
        {contributor.linkedin && (
          <a rel="noopener" target="_blank" href={contributor.linkedin}>
            <LinkedIn />
          </a>
        )}
      </div>
    </div>
  );
}

export default ContributorCard;
