import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Header from "~/views/compositions/navigation/header";
import Page from "~/models/page";
import Service from "~/models/service";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import FlowPreview from "~/views/components/flow/preview";
import Window from "~/views/identity/window";
import ExploreFooter from "~/views/compositions/explore/footer";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.ServicePageQuery;
};

const ServicePage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const service = new Service(data.service as Queries.ContentfulService);

  page.breadcrumb[2].name = page.breadcrumb[2].name.replaceAll(
    "{service.name}",
    service.name
  );
  page.breadcrumb[2].pathname = page.breadcrumb[2].pathname.replaceAll(
    "{service.slug}",
    service.slug
  );

  return (
    <Frame>
      <Header />
      <Tagline />
      <Breadcrumb
        breadcrumb={page.breadcrumb}
        className="max-w-screen-xl !px-5 sm:!px-8 lg:!px-14"
      />

      <div className="flex-col max-w-screen-xl mx-auto px-5 sm:px-8 lg:px-14 pt-4 lg:pt-8 pb-8 lg:pb-14 flex justify-between gap-5">
        <div className="flex items-center gap-4 lg:gap-6">
          <GatsbyImage
            image={getImage(service.logo.localFile as any)!}
            alt={service.name}
            loading="eager"
            className="overflow-hidden w-16 h-16 lg:w-20 lg:h-20 rounded-2xl border-[0.5px] border-neutral-90"
          />
          <h1 className="text-4xl lg:text-8xl font-extrabold">
            {service.name}
          </h1>
        </div>
        <div className="max-w-[680px]">
          <p className="text-xs leading-7 text-neutral-30">
            {service.description}
          </p>

          <a
            rel="noopener noreferrer"
            target="_blank"
            className="block text-neutral-30 hover:highlighted-link mt-2 text-xs"
            href={service.url}>
            <span className="align-middle underline">{`App Storeで${service.name}アプリをダウンロード`}</span>
            <Window
              color="#2E2E30"
              className="align-middle inline-block ml-[6px] mb-[3px]"
            />
          </a>
        </div>
      </div>

      <section className="bg-neutral-99 py-8 md:py-12">
        <div className="max-w-screen-xl mx-auto lg:px-14">
          <ul className="grid py-5 sm:py-10 md:py-12 px-5 sm:px-8 lg:px-10 bg-white lg:rounded-2xl grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-x-2 sm:gap-x-10 md:gap-x-14 gap-y-5 sm:gap-y-10">
            {service.flows.map((flow) => (
              <li key={flow.id} className="relative">
                <FlowPreview flow={flow} />
              </li>
            ))}
          </ul>
        </div>
      </section>

      <ExploreFooter />
    </Frame>
  );
};

export default ServicePage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const service = new Service(data.service as Queries.ContentfulService);

  page.title = page.title.replaceAll("{service.name}", service.name);
  page.metaTitle = page.metaTitle.replaceAll("{service.name}", service.name);
  page.url.pathname = `/explore/${service.slug}/`;
  page.description = service.description;
  page.breadcrumb[2].name = page.breadcrumb[2].name.replaceAll(
    "{service.name}",
    service.name
  );
  page.breadcrumb[2].pathname = page.breadcrumb[2].pathname.replaceAll(
    "{service.slug}",
    service.slug
  );

  return <SEO page={page} />;
};

export const query = graphql`
  query ServicePage($id: String) {
    page: contentfulPage(
      url: { pathname: { eq: "/explore/{service.slug}/" } }
    ) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
      image {
        cover {
          publicURL
        }
      }
    }
    service: contentfulService(id: { eq: $id }) {
      id
      slug
      name
      url
      description {
        description
      }
      category {
        id
        name
      }
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
          }
        }
      }
      flows {
        id
        slug
        name
        type {
          id
          slug
          name
        }
        posters {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        videos {
          contentful_id
          title
          description
          file {
            url
          }
          mimeType
          localFile {
            publicURL
          }
        }
        data {
          internal {
            content
          }
        }
        service {
          id
          slug
          name
          url
          category {
            id
            name
          }
          logo {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
              }
            }
          }
        }
      }
    }
  }
`;
