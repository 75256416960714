import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Url from "~/models/url";
import Article from "~/models/article";
import Page from "~/models/page";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import SEO from "~/views/components/generic/seo";
import Header from "~/views/compositions/navigation/header";
import TableOfContents from "~/views/components/generic/table-of-contents";
import RichText from "~/views/components/content/richtext";
import Tag from "~/views/elements/generic/tag";
import Category from "~/views/elements/generic/category";
import ArticleItem from "~/views/components/article/item";
import Section from "~/views/layout/section";
import PopularArticles from "~/views/compositions/article/popular";
import RecommendedArticles from "~/views/compositions/article/recommended";
import ShareButtons from "~/views/components/generic/share-buttons";
import formatdate from "~/helpers/formatdate";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import useScrollLogs from "~/helpers/use-scroll-logs";
import ContributorCard from "~/views/components/contributor/card";
import SideNavigationBanner from "~/views/components/advertisement/side-navigation";
import Tagline from "~/views/components/generic/tagline";
import cx from "classnames";

type Props = {
  data: Queries.ArticlePageQuery;
};

const ArticlePage = ({ data }: Props) => {
  useScrollLogs("content_view");

  const [currentSection, setCurrentSection] = useState("") as any;

  const page = new Page(data.page as Queries.ContentfulPage);

  const relatedArticles = data.relatedArticles.nodes.map(
    (article) => new Article(article as Queries.ContentfulPage)
  );

  const latestArticles = data.latestArticles.nodes.map(
    (article) => new Article(article as Queries.ContentfulPage)
  );

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const ids = entries
        .filter((entry) => entry.isIntersecting)
        .map((entry) => entry.target.id);

      if (ids.length > 0) {
        setCurrentSection(ids[0]);
      }
    });

    document.querySelectorAll(".heading").forEach((heading) => {
      observer.observe(heading);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Frame>
      <Header />

      <Tagline />

      <Container className="pb-3 pt-5 w1180:pt-7 w1180:pb-3 grid grid-cols-12 grid-gap-x md:max-w-[780px] w1180:max-w-full mx-auto">
        <ul className="col-start-1 col-end-13 w1180:col-start-2 w1180:col-end-9">
          {page.breadcrumb.map((url: Url, index: number) => {
            return (
              <li
                key={url.id}
                className="inline-block mr-2 font-medium text-4xs">
                <Link
                  aria-label={url.name}
                  to={url.pathname}
                  className="mr-2 inline-block align-middle hover:underline text-neutral-60 hover:underline-offset-2">
                  {url.name}
                </Link>

                {page.breadcrumb.length !== index + 1 && (
                  <span className="inline-block align-middle text-neutral-90">
                    /
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      </Container>

      <Container className="pb-8 pt-6 w1180:pt-7 w1180:pb-16 grid grid-cols-12 grid-gap-x md:max-w-[780px] w1180:max-w-full mx-auto">
        <div className="col-start-1 col-end-13 w1180:col-start-2 w1180:col-end-9">
          {page.category && <Category className="mb-4" label={page.category} />}

          <h1 className="mb-4 text-4xl sm:text-7xl w1180:text-9xl font-bold leading-[1.4]">
            {page.title}
          </h1>

          <div className="flex justify-between items-start">
            <div className="xl:flex gap-8 items-center">
              <div className="flex items-center">
                <span className="text-2xs text-neutral-60 break-keep">
                  最終更新日：{formatdate(page.updatedAt)}
                </span>

                {!page.contributors.length && (
                  <span className="ml-4 text-2xs text-neutral-60 break-keep">
                    編集部
                  </span>
                )}
              </div>

              <div>
                {page.labels.map((label) => (
                  <Tag
                    key={label.id}
                    label={label}
                    className="mr-2 mt-2 xl:mt-0"
                  />
                ))}
              </div>
            </div>

            <ShareButtons page={page} />
          </div>
        </div>
      </Container>

      <Container className="grid grid-cols-12 grid-gap-x md:max-w-[780px] w1180:max-w-full mx-auto">
        <article className="col-start-1 col-end-13 w1180:col-start-2 w1180:col-end-9">
          <div className="mb-6 sm:mb-10 w1180:mb-20">
            <div className="bg-neutral-99 rounded-2xl overflow-hidden isolate aspect-[1/0.88] sm:w-[540px] md:w-[518px] mx-auto">
              {page.image && (
                <GatsbyImage
                  image={getImage(page.image.thumbnail as any)!}
                  alt={page.title}
                  className=""
                  loading="eager"
                />
              )}
            </div>
          </div>

          {page.tableOfContents && (
            <TableOfContents
              page={page}
              className="w1180:hidden mb-6 sm:mb-10 w1180:mb-20"
            />
          )}

          <RichText id="content">{page.content}</RichText>

          <div className="flex items-center flex-wrap gap-x-4 gap-y-2">
            {page.category && <Category label={page.category} />}
            {page.labels
              .filter((_: any, index: any) => index > 0)
              .map((label) => (
                <Tag key={label.id} label={label} />
              ))}
          </div>

          {page.contributors.map((contributor) => (
            <ContributorCard key={contributor.id} contributor={contributor} />
          ))}
        </article>

        <div
          className={cx(
            "hidden w1180:flex flex-col col-start-9 col-end-13 top-[106px] max-w-[284px] max-h-[calc(100vh-150px)]",
            {
              sticky: page.tableOfContents,
            }
          )}>
          {page.tableOfContents && (
            <TableOfContents
              page={page}
              currentSection={currentSection}
              onSelect={(hash) => {
                setCurrentSection(hash);
              }}
              className="[overflow:overlay]"
            />
          )}

          {!page.tableOfContents && (
            <React.Fragment>
              <RecommendedArticles
                currentArticle={page}
                className="mb-[120px]"
              />
              <PopularArticles className="sticky top-[106px]" />
            </React.Fragment>
          )}

          <SideNavigationBanner page={page} />
        </div>
      </Container>

      <Container className="grid grid-cols-12 grid-gap-x md:max-w-[780px] w1180:max-w-full mx-auto">
        <React.Fragment>
          {relatedArticles.length > 0 && (
            <Section
              title="関連記事"
              label="Related Articles"
              className="col-start-1 col-end-13 w1180:col-start-2 w1180:col-end-9 mt-[140px]">
              <ul className="grid grid-gap-y">
                {relatedArticles.map((article) => (
                  <li key={article.id}>
                    <ArticleItem article={article} />
                  </li>
                ))}
              </ul>
            </Section>
          )}
        </React.Fragment>

        <React.Fragment>
          {latestArticles.length > 0 && (
            <Section
              title="最新の記事"
              label="Latest Articles"
              className="col-start-1 col-end-13 w1180:col-start-2 w1180:col-end-9 mt-[160px]">
              <ul className="grid grid-gap-y">
                {latestArticles.map((article) => (
                  <li key={article.id}>
                    <ArticleItem article={article} />
                  </li>
                ))}
              </ul>
            </Section>
          )}
        </React.Fragment>
      </Container>

      <NewsletterSubscription className="mt-[160px]" type="dark" />
    </Frame>
  );
};

export default ArticlePage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query ArticlePage($id: String, $labels: [String]) {
    page: contentfulPage(id: { eq: $id }) {
      ...PageFields
    }
    relatedArticles: allContentfulPage(
      filter: {
        id: { ne: $id }
        labels: { elemMatch: { slug: { in: $labels } } }
        type: { eq: "Article" }
      }
      sort: { releasedAt: DESC }
      limit: 4
    ) {
      nodes {
        ...PageThumbnailFields
      }
    }
    latestArticles: allContentfulPage(
      sort: { releasedAt: DESC }
      filter: { id: { ne: $id }, type: { eq: "Article" } }
      limit: 4
    ) {
      nodes {
        ...PageThumbnailFields
      }
    }
  }
`;
