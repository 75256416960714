import React, { useEffect } from "react";
import { graphql } from "gatsby";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Header from "~/views/compositions/navigation/header";
import PageHeader from "~/views/compositions/explore/header";
import Page from "~/models/page";
import Service from "~/models/service";
import Category from "~/models/service-category";
import ServiceCard from "~/views/components/service/card";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import ExploreFooter from "~/views/compositions/explore/footer";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.ServiceCategoryPageQuery;
};

const ServiceCategoryPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const category = new Category(
    data.category as Queries.ContentfulServiceCategory
  );
  const services = data.services.nodes.map(
    (service) => new Service(service as Queries.ContentfulService)
  );

  page.breadcrumb[3].name = page.breadcrumb[3].name.replaceAll(
    "{category.name}",
    category.name
  );
  page.breadcrumb[3].pathname = page.breadcrumb[3].pathname.replaceAll(
    "{category.slug}",
    category.slug
  );

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("preventXScroll");
  }, []);

  return (
    <Frame>
      <Header />
      <Tagline />

      <Breadcrumb
        breadcrumb={page.breadcrumb}
        className="max-w-screen-xl !px-5 lg:!px-14"
      />

      <PageHeader
        title={category.name}
        label={category.label}
        description={category.description}
        className="max-w-screen-xl !px-5 lg:!px-14"
      />

      <section className="bg-neutral-99 py-8 md:py-12">
        <ul className="max-w-screen-xl mx-auto lg:px-14">
          {services.map((service) => (
            <li key={service.id} className="mb-12 last:mb-0">
              <ServiceCard service={service} />
            </li>
          ))}
        </ul>
      </section>

      <ExploreFooter />
    </Frame>
  );
};

export default ServiceCategoryPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const category = new Category(
    data.category as Queries.ContentfulServiceCategory
  );

  page.title = page.title.replaceAll("{category.name}", category.name);
  page.metaTitle = page.metaTitle.replaceAll("{category.name}", category.name);
  page.description = category.description;
  page.breadcrumb[3].name = page.breadcrumb[3].name.replaceAll(
    "{category.name}",
    category.name
  );
  page.breadcrumb[3].pathname = page.breadcrumb[3].pathname.replaceAll(
    "{category.slug}",
    category.slug
  );
  page.url.pathname = page.url.pathname.replaceAll(
    "{category.slug}",
    category.slug
  );

  return <SEO page={page} />;
};

export const query = graphql`
  query ServiceCategoryPage($id: String) {
    page: contentfulPage(
      url: { pathname: { eq: "/explore/categories/{category.slug}/" } }
    ) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
      image {
        cover {
          publicURL
        }
      }
    }
    category: contentfulServiceCategory(id: { eq: $id }) {
      id
      name
      description {
        description
      }
      slug
    }
    services: allContentfulService(filter: { category: { id: { eq: $id } } }) {
      nodes {
        id
        slug
        name
        category {
          id
          name
        }
        logo {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
            }
          }
        }
        flows {
          id
          slug
          name
          type {
            id
            slug
            name
          }
          posters {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 320, placeholder: NONE, formats: [WEBP])
              }
            }
          }
          videos {
            contentful_id
            title
            description
            file {
              url
            }
            mimeType
            localFile {
              publicURL
            }
          }
          data {
            internal {
              content
            }
          }
          service {
            id
            slug
            name
            url
            category {
              id
              name
            }
            logo {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 320
                    placeholder: NONE
                    formats: [WEBP]
                  )
                }
              }
            }
          }
        }
      }
    }
  }
`;
