import React, { useState, useMemo, useEffect } from "react";
import { useLocation } from "@reach/router";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import algoliasearch from "algoliasearch";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Header from "~/views/compositions/navigation/header";
import Hero from "~/views/compositions/explore/hero";
import Play from "~/views/identity/play";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import ExploreFooter from "~/views/compositions/explore/footer";
import FlowSearch from "~/views/components/flow/search";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.SearchFlowPageQuery;
};

const hitsPerPage = 100;

const SearchFlowPage = ({ data }: Props) => {
  const location = useLocation();
  const urlSearchParams = new URLSearchParams(location.search) as any;
  const params = Object.fromEntries(urlSearchParams.entries());
  const [searchQuery, setsearchQuery] = useState(params.q || "");
  const [flows, setFlows] = useState([]);
  const page = new Page(data.page as Queries.ContentfulPage);

  const client = useMemo(
    () =>
      algoliasearch(
        process.env.GATSBY_ALGOLIA_APP_ID!,
        process.env.GATSBY_ALGOLIA_SEARCH_KEY!
      ),
    []
  );

  const index = client.initIndex(process.env.GATSBY_ALGOLIA_FLOW_INDEX_NAME!);

  useEffect(() => {
    search(searchQuery);
  }, []);

  const search = async (query: string) => {
    if (query.trim() == "") {
      return;
    }

    setsearchQuery(query);

    try {
      let result = await index.search(query, {
        page: 0,
        hitsPerPage,
      });

      const hits = result.hits;

      history.pushState({}, "", `?q=${query}`);

      if (typeof window !== "undefined") {
        (window as any).dataLayer.push({
          event: "search",
          search_term: query,
        });
      }

      setFlows(hits as any);
    } catch (error) {}
  };

  return (
    <Frame>
      <Header />
      <Tagline />

      <div className="bg-[#13303F] relative z-20">
        <div className="absolute inset-0 overflow-hidden">
          <img
            src="/images/explore-hero-01.png"
            height="455"
            width="856"
            alt=""
            loading="lazy"
            className="absolute -top-[189px] -left-[320px] z-0 hidden lg:block"
          />
          <img
            src="/images/explore-hero-02.png"
            height="654"
            width="946"
            alt=""
            loading="lazy"
            className="absolute -top-[168px] -right-[260px] z-0 hidden lg:block"
          />
        </div>

        <Breadcrumb
          breadcrumb={page.breadcrumb}
          background="dark"
          className="z-10 relative !px-5 md:!px-14"
        />

        <Hero
          title="Search user flows"
          description={`"${searchQuery}"の検索結果`}
          className="z-10 relative">
          <FlowSearch
            onSearch={(query: string) => search(query)}
            defaultSearchQuery={searchQuery}
          />
        </Hero>
      </div>

      <section className="bg-neutral-99">
        <ul className="max-w-screen-xl mx-auto px-5 sm:px-14 py-8 sm:py-12 grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-y-8 sm:gap-y-12 gap-2 sm:gap-x-10">
          {flows.map((flow: any) => (
            <li key={flow.id}>
              <div className="sm:py-4 sm:px-5 rounded-2xl sm:bg-white group-hover:bg-black">
                <div className="flex flex-col-reverse sm:flex-row justify-between sm:items-center mb-2 md:mb-3">
                  <Link
                    to={`/explore/${flow.service!.slug}/`}
                    className="flex items-center gap-2">
                    <GatsbyImage
                      image={getImage(flow.service!.logo.localFile as any)!}
                      alt={flow.service!.name}
                      loading="eager"
                      className="overflow-hidden w-6 md:w-8 h-6 md:h-8 rounded-[6px] md:rounded-lg border-[0.5px] border-neutral-90"
                    />
                    <div className="md:text-2xl font-bold hover:highlighted-link">
                      {flow.service!.name}
                    </div>
                  </Link>
                </div>

                <div className="hidden sm:block text-neutral-60 text-4xs md:text-3xs font-medium">
                  <Link
                    to={`/explore/categories/${flow.service!.category.slug}/`}
                    className="hover:underlined-link">
                    {flow.service!.category.name}
                  </Link>
                  ・
                  <Link
                    to={`/explore/types/${flow.type.slug}/`}
                    className="hover:underlined-link">
                    {flow.name}
                  </Link>
                </div>

                <div className="group mt-2 md:mt-6 relative transition-all ease-in-out duration-500 hover:md:opacity-[0.85] hover:md:scale-[1.015]">
                  <Play className="z-10 absolute top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none" />
                  <Link
                    to={`/explore/${flow.service!.slug}/${flow.slug}/`}
                    className="overflow-hidden border border-neutral-90-0.6 rounded-xl sm:max-w-[176px] mx-auto block">
                    <GatsbyImage
                      image={getImage(flow.posters[0].localFile as any)!}
                      alt={flow.name}
                      loading="eager"
                    />
                  </Link>
                </div>

                <div className="text-neutral-60 text-3xs font-medium mt-2 sm:hidden">
                  <Link
                    to={`/explore/types/${flow.type.slug}/`}
                    className="hover:underlined-link">
                    {flow.name}
                  </Link>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </section>

      <ExploreFooter />
    </Frame>
  );
};

export default SearchFlowPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query SearchFlowPage {
    page: contentfulPage(url: { pathname: { eq: "/explore/search/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
      image {
        cover {
          publicURL
        }
      }
    }
  }
`;
