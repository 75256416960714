import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import cx from "classnames";
import Page from "~/models/page";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Container from "~/views/layout/container";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Header from "~/views/compositions/navigation/header";
import Check from "~/views/identity/check";
import NewsletterSubscription from "~/views/components/newsletter/subscription";
import Tagline from "~/views/components/generic/tagline";

enum State {
  Default,
  Submitting,
  Submitted,
}

type Props = {
  data: Queries.AboutUsPageQuery;
};

const ContactPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);

  const [state, setState] = useState(State.Default);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [website, setWebsite] = useState("");
  const [message, setMessage] = useState("");
  const [privacy, setPrivacy] = useState(false);

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
    privacy: false,
  });

  async function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const endpoint = process.env.GATSBY_GETFORM_ENDPOINT as string;

    const data = {
      type: "contact",
      name,
      email,
      organization,
      website,
      message,
      privacy,
    };

    setErrors({
      name: name.length == 0,
      email: email.length == 0,
      message: message.length == 0,
      privacy: !privacy,
    });

    if (
      name.length == 0 ||
      email.length == 0 ||
      message.length == 0 ||
      !privacy
    ) {
      return;
    }

    try {
      setState(State.Submitting);

      let response = await fetch(endpoint, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      if (typeof window !== "undefined") {
        (window as any).dataLayer.push({
          event: "submit",
          type: "contact",
        });
      }

      setState(State.Submitted);
    } catch (error) {
      setState(State.Default);
    }
  }

  return (
    <Frame>
      <Header />

      <Tagline />

      <Breadcrumb breadcrumb={page.breadcrumb} className="xl:max-w-[1328px]" />

      <Container className="py-container">
        <div className="max-w-[768px] mx-auto">
          <h1 className="mb-8 sm:mb-16 font-bold text-2xl sm:text-6xl text-center">
            お問い合わせ
          </h1>
          <p className="mb-8 sm:mb-16 text-neutral-30 leading-[2]">
            unprintedにお立ち寄りいただきありがとうございます。
            サイトに関するお問い合わせや、イベント情報の掲載・削除依頼については以下のフォームより受け付けております。コンテンツ制作に関わってみたいという方も、ぜひお気軽にお問い合わせください。
          </p>

          <form onSubmit={(e) => onSubmit(e)}>
            <div className="mb-20 flex flex-col gap-10">
              <div className="flex flex-col sm:flex-row gap-6">
                <div className="flex-1">
                  <label
                    htmlFor="name"
                    className="mb-3 block text-xs text-neutral-30">
                    <span>氏名</span>
                    <span className="ml-1 text-orange-dark">*</span>
                  </label>
                  <input
                    name="name"
                    type="text"
                    className={cx(
                      "w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black",
                      {
                        "border-orange-dark border": errors.name,
                      }
                    )}
                    value={name}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        name: false,
                      });
                      setName(e.target.value);
                    }}
                  />
                </div>

                <div className="flex-1">
                  <label
                    htmlFor="email"
                    className="mb-3 block text-xs text-neutral-30">
                    <span>メールアドレス</span>
                    <span className="ml-1 text-orange-dark">*</span>
                  </label>
                  <input
                    name="email"
                    type="email"
                    className={cx(
                      "w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black",
                      {
                        "border-orange-dark border": errors.email,
                      }
                    )}
                    value={email}
                    onChange={(e) => {
                      setErrors({
                        ...errors,
                        email: false,
                      });
                      setEmail(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex flex-col sm:flex-row gap-6">
                <div className="flex-1">
                  <label
                    htmlFor="organization"
                    className="mb-3 block text-xs text-neutral-30">
                    所属・会社名
                  </label>
                  <input
                    name="organization"
                    type="text"
                    className="w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black"
                    value={organization}
                    onChange={(e) => {
                      setOrganization(e.target.value);
                    }}
                  />
                </div>

                <div className="flex-1">
                  <label
                    htmlFor="website"
                    className="mb-3 block text-xs text-neutral-30">
                    ホームページ
                  </label>
                  <input
                    name="website"
                    type="text"
                    className="w-full border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black"
                    value={website}
                    onChange={(e) => {
                      setWebsite(e.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="flex-1">
                <label
                  htmlFor="message"
                  className="mb-3 block text-xs text-neutral-30">
                  <span>お問い合わせ内容</span>
                  <span className="ml-1 text-orange-dark">*</span>
                </label>
                <textarea
                  name="message"
                  className={cx(
                    "w-full h-[144px] border border-neutral-60 rounded p-[15px] leading-4 focus-visible:outline-black",
                    {
                      "border-orange-dark border": errors.message,
                    }
                  )}
                  value={message}
                  onChange={(e) => {
                    setErrors({
                      ...errors,
                      message: false,
                    });
                    setMessage(e.target.value);
                  }}
                />
              </div>

              <div className="flex flex-col sm:flex-row gap-6">
                <div className="flex-1">
                  <div className="mb-4 flex items-center">
                    <input
                      id="privacy"
                      name="privacy"
                      type="checkbox"
                      className="mr-2 cursor-pointer border border-neutral-60 accent-black w-4 h-4"
                      onChange={(e) => {
                        setErrors({
                          ...errors,
                          privacy: false,
                        });
                        setPrivacy(e.target.checked);
                      }}
                    />
                    <label
                      htmlFor="privacy"
                      className="text-xs text-neutral-30 cursor-pointer">
                      <span>個人情報の取扱について同意します</span>
                      <span className="ml-1 text-orange-dark">*</span>
                    </label>
                  </div>
                  <p className="pl-6 text-3xs text-neutral-30 leading-[1.5]">
                    お客様の情報は大切に保管し、unprinted及び関連会社以外に提供することはありません。個人情報の取扱についての詳細は
                    <Link
                      aria-label="プライバシーポリシー"
                      to="/privacy-policy"
                      className="underline hover:inline-link">
                      プライバシーポリシー
                    </Link>
                    をご覧ください。
                  </p>
                </div>
              </div>
            </div>

            {Object.values(errors).indexOf(true) != -1 && (
              <p className="max-w-screen-sm mx-auto mb-6 text-3xs text-orange-dark text-center">
                <span>※</span>
                {errors.name && <span className="mr-1">「氏名」</span>}
                {errors.email && (
                  <span className="mr-1">「メールアドレス」</span>
                )}
                {errors.message && (
                  <span className="mr-1">「お問い合わせ内容」</span>
                )}
                {errors.privacy && (
                  <span className="mr-1">「個人情報の取扱について同意」</span>
                )}
                <span>
                  は必須項目です。
                  <br />
                  ご入力の上、送信ボタンを押してください。
                </span>
              </p>
            )}

            <div className="flex justify-center">
              <button
                aria-label="送信"
                type="submit"
                className={cx("cta flex gap-3 items-center ", {
                  "pointer-events-none": state != State.Default,
                })}>
                {state == State.Submitting && <div className="loader"></div>}
                {state == State.Submitted && <Check width={20} />}
                <span>送信</span>
              </button>
            </div>
          </form>
        </div>
      </Container>

      <NewsletterSubscription type="dark" />
    </Frame>
  );
};

export default ContactPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query ContactPage {
    page: contentfulPage(url: { pathname: { eq: "/contact/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
    }
  }
`;
