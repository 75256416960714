import React from "react";

import Contributor from "~/models/contributor";

type Props = {
  node?: any;
};

function ContributorCard({ node }: Props) {
  const { title, contributor } = node.data.target;
  const c = new Contributor(contributor!);

  return (
    <div className="my-10 bg-neutral-99 py-4 px-6 rounded">
      <div className="mb-1 text-2xs font-semibold text-neutral-60">{title}</div>
      <div className="text-neutral-30 font-semibold leading-[2em]">
        {contributor.name}
      </div>
      <p className="text-xs leading-[2em]">{c.biography}</p>
    </div>
  );
}

export default ContributorCard;
