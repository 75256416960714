import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import SEO from "~/views/components/generic/seo";
import Frame from "~/views/layout/frame";
import Header from "~/views/compositions/navigation/header";
import Page from "~/models/page";
import Category from "~/models/service-category";
import Breadcrumb from "~/views/compositions/navigation/breadcrumb";
import Tagline from "~/views/components/generic/tagline";

type Props = {
  data: Queries.ServiceCategoryListPageQuery;
};

const ServiceCategoryListPage = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  const categories = data.categories.nodes.map(
    (category) => new Category(category as Queries.ContentfulServiceCategory)
  );

  useEffect(() => {
    document.getElementsByTagName("body")[0].classList.remove("preventXScroll");
  }, []);

  return (
    <Frame>
      <Header />
      <Tagline />

      <div className="bg-neutral-99">
        <Breadcrumb
          breadcrumb={page.breadcrumb}
          className="max-w-screen-xl !px-5 lg:!px-14"
        />

        <div className="max-w-screen-xl px-5 lg:px-14 mx-auto pt-12 pb-80">
          <h2 className="text-2xl text-neutral-30 font-bold mb-8">
            カテゴリー
          </h2>
          <ul className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-x-8 gap-y-10">
            {categories.map((category: any) => (
              <li
                key={category.id}
                className="font-medium text-xs text-neutral-60 hover:highlighted-link">
                <Link to={`/explore/categories/${category.slug}`}>
                  {category.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Frame>
  );
};

export default ServiceCategoryListPage;

export const Head = ({ data }: Props) => {
  const page = new Page(data.page as Queries.ContentfulPage);
  return <SEO page={page} />;
};

export const query = graphql`
  query ServiceCategoryListPage {
    page: contentfulPage(url: { pathname: { eq: "/explore/categories/" } }) {
      id
      title
      description {
        description
      }
      breadcrumb {
        ...UrlFields
      }
      url {
        ...UrlFields
      }
      type
      releasedAt
      updatedAt
      image {
        cover {
          publicURL
        }
      }
    }
    categories: allContentfulServiceCategory {
      nodes {
        id
        name
        description {
          description
        }
        slug
      }
    }
  }
`;
