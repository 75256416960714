import React from "react";
import { graphql, Link } from "gatsby";

type Props = {
  className?: string;
};

function Tagline({ className }: Props) {
  return (
    <div className="bg-neutral-99 py-5 sm:py-[23px] text-center">
      <h1 className="text-3xs font-semibold">
        <Link to="/about-us" className="hover:underline underline-offset-2">
          「より良い未来」をつくるデザイナーのためのWork & Lifeマガジン
        </Link>
      </h1>
    </div>
  );
}

export default Tagline;
